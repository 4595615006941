<template>
    <div>
        <el-empty description="关注公众号，回复【login】获取测试账号" :image-size="300" image="https://98k-hk666.oss-cn-shanghai.aliyuncs.com/iblog/img/qrcode_for_gh_f9dfb22c8cf6_344.jpg?versionId=CAEQQBiBgMDIw9r_lBgiIGQ0YzEwZDQxYWVhMjQ2MDU5M2JiYzEwZjZhYmVlY2Q0"></el-empty>
    </div>
</template>

<script>
// 导出模块
export default {};
</script>
// scoped 样式只在本组件使用
<style scoped>
/**
 * 导入css样式组件
 * @import "../assets/css/components/index.css";
 */
</style>